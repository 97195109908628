@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.g-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  outline: none;
  border: none;
  background-image: linear-gradient(270deg, #5eb0ff 0%, #3078ff 100%);
  border-radius: 22px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}
.g-button-heco-theme.g-button:hover {
  background-image: linear-gradient(270deg, #6EB8FF 0%, #4585FF 100%);
}
.g-button-heco-theme.g-button--normal:hover {
  background-image: linear-gradient(270deg, #5eb0ff 0%, #3078ff 100%);
  color: #fff;
  border: none;
}
.g-button a{
  display: block;
  width: 100%;
  line-height: 44px;
  height: 44px;
}
.g-button-bsc-theme {
  background-image: linear-gradient(270deg, #ffc62c 0%, #ffa954 100%);
}
.g-button-bsc-theme.g-button:hover {
  background-image: linear-gradient(270deg, #FFCB41 0%, #FFB165 100%);
}
.g-button-bsc-theme.g-button--normal:hover {
  background-image: linear-gradient(270deg, #ffc62c 0%, #ffa954 100%);
  color: #fff;
  border: none;
}

.g-button-eth-theme {
  background-image: linear-gradient(270deg, #1165E4 2%, #233BB8 100%);
}
.g-button-eth-theme.g-button:hover {
  background-image: linear-gradient(270deg, #1f6de2 2%, #2c43b9 100%);
}
.g-button-eth-theme.g-button--normal:hover {
  background-image: linear-gradient(270deg, #1f6de2 2%, #2c43b9 100%);
  color: #fff;
  border: none;
}
.g-button:active {
  opacity: 0.7;
}
.g-button:disabled {
  opacity: 0.5;
}
.g-button-heco-theme.g-button--normal {
  background-image: none;
  background-color: #fff;
  color: #3078ff;
  border: 1px solid #3078ff;
}
.g-button-bsc-theme.g-button--normal {
  background-image: none;
  background-color: #fff;
  color: #ffa34f;
  border: 1px solid #ffa953;
}
.g-button-eth-theme.g-button--normal {
  background-image: none;
  background-color: #fff;
  color: #233BB8;
  border: 1px solid #233BB8;
}
.g-button--loading::before {
  content: "";
  width: 20px;
  height: 20px;
  background: url(../../assets/img/loading.png) no-repeat center center;
  background-size: contain;
  animation: loading 1s linear infinite;
}
.g-button--normal.g-button--loading::before {
  background: url(../../assets/img/loading-lan.png) no-repeat center center;
  background-size: contain;
}
.g-button-bsc-theme.g-button--normal.g-button--loading::before {
  background: url(../../assets/img/loading-blue-bsc.png) no-repeat center center;
  background-size: contain;
}
