.g-filter {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 16px;
  max-width: 1200px;
  margin: 20px auto 0;
  height: 60px;
  display: flex;
  align-items: center;
  padding:0 40px;
  box-sizing: border-box;
  justify-content: space-between;
}
/* .g-filter.filter-eth{
  padding-top: 15px;
} */
@media screen and (max-width: 1200px) {
  .g-filter{
    margin:0 15px;
    margin-top: 20px;
    flex-wrap: wrap;
    /* justify-content: center; */
    padding: 0 0 14px;
    height: auto;
  }
  .g-filter.filter-eth{
    padding-top: 15px;
  }
  .g-switch-wrapper {
    margin-left: 16px;
  }
}
