.g-switch-wrapper {
  display: flex;
  align-items: center;
}
.g-switch {
  background: #f6f8fb;
  border: 1px solid #edeff2;
  border-radius: 12px;
  display: flex;
  width: 48px;
  height: 24px;
  align-items: center;
  padding: 0 2px;
  transition: all 0.2s ease-in;
}
.g-switch--checked {
  background-color: #3078ff;
}
.g-switch-wrapper--bsc .g-switch--checked {
  background-color: #FFA954;
}
.g-switch-wrapper--eth .g-switch--checked {
  background-color: #233BB8;
}
.g-switch::after {
  content: "";
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
}
.g-switch--checked::after {
  transform: translateX(28px);
}
.g-switch-label {
  margin-left: 20px;
  font-size: 14px;
  color: #8391a8;
  cursor: pointer;
}
