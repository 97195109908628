.g-pop-QuesttipStakings {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(../../assets/img/icon-question.svg) no-repeat center center;
  position: relative;
  margin-left: 5px;
  margin-top: 13px;
  flex: none;
}

.g-pop-QuesttipStakings::after {
  position: absolute;
  transform: translate(-25%, -25%);
  content: '';
  width: 200%;
  height: 200%;
  background-color: transparent;
}

.g-pop-tips__inner-stakings {
  position: absolute;
  min-width: 200px;
  max-width: 300px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px 0 #EDEFF2;
  border-radius: 16px;
  padding: 10px;
  border-radius: 5px;
  /* bottom: 20px;
  
  transform: translateX(-50%); */
  left: -40px;
  top: -90px;
  z-index: 100;
  font-weight: none;
}

.g-pop-tips__inner-stakings::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  bottom: -5px;
  left: 45px;
  transform: translateX(-50%) rotate(45deg);
}

.question__item_Staking {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}

.question__item-text_staking {
  line-height: 20px;
  font-weight: normal;
  font-size: 12px;
}

@media screen and (min-width: 1200px) {
  .g-pop-tips__inner-stakings {
    min-width: 220px;
    max-width: 300px;
    /* left: -550%; */
  }

  .g-pop-tips__inner-stakings::after {
    /* left: 0%; */
  }
}

@media screen and (max-width: 900px) {
  .g-pop-tips__inner-stakings {
    min-width: 200px;
    max-width: 300px;
    /* left: -200%; */
  }

  .g-pop-tips__inner-stakings::after {
    /* left: 80%; */
    bottom: 100px;
  }
}