.home-btn-hui {
    color: #888888 !important;
    border: 1px solid;
    cursor: pointer;
    height: 32px;
    padding: 0 14px;
    font-size: 14px;
    min-width: 96px;
    background: #f3f2f2;
    font-weight: 700;
    border: 1px solid #999999 !important;
    border-radius: 22px;
    /* background: #eeeeee; */
}

.home-btn-hui:hover {
    background: #f3f2f2 !important;
}

@media screen and (max-width: 900px) {
    .home-btn-hui {
        width: 70px !important;
        min-width: 70px !important;
    }
}