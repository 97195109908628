.nav__language-container {
    width: 100%;
    height: 50px;
    /* display: flex; */
    align-items: center;
    /* justify-content: center; */
    cursor: pointer;
    position: relative;
    /* padding-left: 32px; */
    /* box-sizing: border-box; */
    border-top: 1px solid #EDEFF2;
    text-align: center;
    line-height: 50px;
}
.nav__language-container:last-of-type {
    /* margin-top: 20px; */
}
.nav__language-container span {
    font-size: 14px;
    color:#131D32;
    font-weight: 700;
}

.nav__language {
    transition: all linear .5s;
    -webkit-transition: all linear .5s;
    -moz-transition: all linear .5s;
    -ms-transition: all linear .5s;
    -o-transition: all linear .5s;
    font-family: MicrosoftYaHei-Bold;
    font-size: 14px;
    color: #8391A8;
    letter-spacing: 0;
    font-weight: 700;
}
.language__icon {
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 8px;
    background: url('../../images/icon-yuyan.svg') no-repeat center center;
    margin-right: 8px;
}
/* .nav__language-container:hover .language__icon {
    background: url('../../images/icon-yuyan-blue.svg') no-repeat center center;
}
.nav__language-container:hover .nav__language {
    color: #3078FF;
} */
.dropdown__option {
    background: #FFFFFF;
    box-shadow: 0 4px 12px 0 #EDEFF2;
    border-radius: 16px;
    overflow: hidden;
    position: absolute;
    left: 20px;
    display: none;
}
.option__top {
    /* bottom: 60px;
    top: unset; */
    top: 50px;
    left: -170px;
}
.option__bottom {
    top: 60px;
    bottom: unset;
}
.option__item {
    border-bottom: 1px solid #EDEFF2;
    min-width: 198px;
    padding: 0 10px;
    box-sizing: border-box;
    height: 60px;
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #131D32;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
}
.option__item:hover {
    background-color: #F1FAFF;
    color: #3078FF;
}
.option__item-bsc:hover {
    background-color: #F1FAFF;
    color: #FFA34F;
}
.option__item-eth:hover {
    background-color: #F1FAFF;
    color: #233BB8;
}
.option__item:last-of-type {
    border-bottom: none;
}
.option__show {
    display: block;
    z-index: 100;
}

/* 侧边菜单 去掉语言上border */
.slider__content .nav__language-container {
    border-top: none;
}

@media screen and (min-width: 1000px) {
    .nav__language-container:hover .language__icon {
        background: url('../../images/icon-yuyan-blue.svg') no-repeat center center;
    }
    .nav__language-container:hover .language__icon-eth {
        background: url('../../images/yuyan-eth.svg') no-repeat center center;
    }
    .nav__language-container:hover .language__icon-bsc {
        background: url('../../images/yuyan-bsc.svg') no-repeat center center;
    }
    .nav__language-container:hover .nav__language {
        color: #3078FF;
    }
    .nav__language-container:hover .nav__language-bsc {
        color: #FFA34F;
    }
}
