.g-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
.g-dialog__inner {
  background-color: #fff;
  border-radius: 16px;
  min-width: 500px;
  padding: 48px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.g-dialog__content {
  padding:0 30px;
  font-size: 20px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.g-dialog__content::before {
  flex-shrink: 0;
  content: "";
  width: 44px;
  height: 44px;
  margin-right: 12px;
  background: url(../../assets/img/icon-success.svg) no-repeat center center;
}
.g-dialog__icon--warning::before {
  background: url(../../assets/img/icon-fail.svg) no-repeat center center;
}
.g-dialog__button {
  width: 90%;
  display: flex;
  justify-content: center;
}
.g-dialog__button .g-button{
  width: 140px;
  margin: 0 10px;
}
@media screen and (max-width: 1200px) {
    .g-dialog__inner{
        min-width: auto;
        width: 80%;
    }
}
