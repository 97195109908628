.option__show-content{
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(237,239,242,1);
    border-radius: 16px;
    width: 160px;
    font-family: MicrosoftYaHei-Bold;
    z-index: 100 !important;
    margin-left:-55px;
    overflow: hidden;
    position: relative;
}
.option__show_dis{
    display: none;
}
.zi_content{
    width: 160px;
    height: 50px;
    line-height: 50px;
    font-family: PingFangSC-Semibold;
font-size: 16px;
letter-spacing: 0;
    border-bottom: 1px solid #EDEFF2;
    color: #131D32;
}
.zi_content:hover{
    color: #3078FF;
}