.g-pop-QuesttipStaking {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(../../assets/img/icon-question.svg) no-repeat center center;
  position: relative;
  margin-left: 5px;
  flex: none;
}

.g-pop-QuesttipStaking::after {
  position: absolute;
  transform: translate(-25%, -25%);
  content: '';
  width: 200%;
  height: 200%;
  background-color: transparent;
}

.g-pop-tips__inner-staking {
  position: absolute;
  min-width: 250px;
  max-width: 300px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px 0 #EDEFF2;
  border-radius: 16px;
  padding: 10px;
  border-radius: 5px;
  /* bottom: 20px;
  
  transform: translateX(-50%); */
  left: 25px;
  top: -150px;
  z-index: 100;
  font-weight: none;
}

.g-pop-tips__inner-staking::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  bottom: 80px;
  left: 0%;
  transform: translateX(-50%) rotate(45deg);
}

.question__item_Staking {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}

.question__item-text_staking {
  line-height: 20px;
  font-weight: normal;
  font-size: 12px;
}
.question__title{
  font-weight: normal;
  /* font-size: 12px; */
}
@media screen and (min-width: 1200px) {
  .g-pop-tips__inner-staking {
    min-width: 250px;
    max-width: 300px;
    /* left: -550%; */
  }

  .g-pop-tips__inner-staking::after {
    left: 0%;
  }
}

@media screen and (max-width: 900px) {
  .g-pop-tips__inner-staking {
    min-width: 250px;
    max-width: 300px;
    top: -80px;
    /* left: -200%; */
  }

  .g-pop-tips__inner-staking::after {
    /* left: 80%; */
    bottom: 150px;
  }
}