.app_ip{
    width: 600px;
    background: #fff;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
}
.app_footer{
    margin-top: 30px;
    text-align: center;
}
.app_footer_check{
    margin-bottom: 20px;
}
.app_footer_check input {
    margin: 0 10px
}
.app_content>div{
    line-height: 20px;
}
.pools__dialog-inner_ip{
    width: 440px;
    background: #ffffff;
    border-radius: 16px;
    padding: 30px;
}
@media screen and (max-width: 1200px) {
    .pools__dialog-inner_ip{
        width: 600px;
    }
}