.slider__mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    display: none;
}
.slider__content {
    position: fixed;
    /* height: 100%; */
    width: 100%;
    background-color: #fff;
    position: absolute;
    right: -240px;
    top: 0;
    transition: right ease-in .8s;
    -webkit-transition: right ease-in .8s;
    -moz-transition: right ease-in .8s;
    -ms-transition: right ease-in .8s;
    -o-transition: right ease-in .8s;
    border-radius: 0 0 16px 16px;
}
.slider__content_all{
    width: 50%;
    /* background: chartreuse; */
    margin: 40px auto 0;
    color:#131D32;
}
.slide__show {
    display: block;
}
.slide__content-show {
    right: 0;
}
.tutoril__container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #EDEFF2;
    padding: 0 12px;
    box-sizing: border-box;
    padding-bottom: 20px;
}
.tutoril__item {
    margin-top: 24px;
    height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tutoril__item span {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #8391A8;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
}
.tutoril__item img {
    width: 10px;
    height: 10px;
    display: block;
}
.lian_wo{
    display: flex;
    margin-bottom: 30px;
    justify-content: space-around;
    margin-top: 20px;
}
.nav__language-containers{
    /* width: 100%; */
    width: 36px;
    height: 36px;
    /* display: flex; */
    /* justify-content: center; */
    cursor: pointer;
    /* padding-left: 32px; */
    /* box-sizing: border-box; */
    /* border-top: 1px solid #EDEFF2; */
    text-align: center;
    line-height: 32px;
    margin-left: 20px;
    position: relative;
}
.nav_style{
    border-radius: 30px;
}
.nav__language-heco{
    border: 1px solid #3078FF;
    color:#3078FF !important;
}
.nav__language-bsc{
    border: 1px solid #ffa954;
    color:#ffa954 !important;
}
.nav__language-eth{
    border: 1px solid #233BB8;
    color:#233BB8 !important;
}
.nav__language-heco:hover {
    color:red !important;
    background:#3078FF ;
}
.nav__language-bsc:hover{
    color:#ffffff !important;
    background:#ffa954 ;
}
.nav__language-eth:hover{
    color:#ffffff !important;
    background:#233BB8 ;
}
.fang_heco{
    color:#3078FF ;
}
.fang_bsc{
    color:#ffa954;
}
.fang_eth{
    color:#233BB8;
}
.nav__language-containers:hover .fang_heco{
    color: #3078FF !important;
}
.nav__language-containers:hover .fang_bsc{
    color: #ffa954 !important;
}
.nav__language-containers:hover .fang_eth{
    color: #233BB8 !important;
}