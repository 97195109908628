.menu__item {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menu__item p {
    font-family: PingFangSC-Medium;
    font-size: 10px;
    color: #8391A8;
    letter-spacing: 0;
    text-align: center;
    line-height: 14px;
    font-weight: 500;
    margin-top: 3px;
}
.menu__item--actived p{
    color: #3078FF;
}
.menu__item--actived .p-bsc{
    color: #FFA34F;
}
.menu__item i {
    width: 20px;
    height: 20px;
    display: block;
}
.menu__item strong{
    height: 20px;
    padding: 0 10px;
    border-radius: 9px 9px 9px 0;
    background: linear-gradient(90deg, #3078FF 0%, rgba(48,120,255,.6) 100%);
    position: absolute;
    font-size: 10px;
    color: #fff;
    right: 25px;
    top: -6px;
    font-weight: 400;
    font-family: PingFangSC-Medium;
    line-height: 20px;
}
.menu__item-bsc strong {
    background: linear-gradient(90deg, #FFA34F 0%, rgba(255,163,79,.6) 100%);
}
.menu__item:first-of-type i {
    background: url(../../images/icon-index.svg) no-repeat center center;
}
.menu__item--actived:first-of-type i{
    background: url(../../images/icon-index-blue.svg) no-repeat center center;
}
.menu__item--actived:first-of-type .i-bsc{
    background: url(../../images/index-bsc.svg) no-repeat center center;
}
.menu__item:nth-of-type(2) i {
    background: url(../../images/icon-high.svg) no-repeat center center;
}
.menu__item--actived:nth-of-type(2) i{
    background: url(../../images/icon-high-blue.svg) no-repeat center center;
}
.menu__item--actived:nth-of-type(2)  .i-bsc{
    background: url(../../images/high-bsc.svg) no-repeat center center;
}



.menu__item:nth-of-type(3) i {
    background: url(../../images/icon-lp.svg) no-repeat center center;
}
.menu__item--actived:nth-of-type(3) i{
    background: url(../../images/icon-lp-blue.svg) no-repeat center center;
}
.menu__item--actived:nth-of-type(3)  .i-bsc{
    background: url(../../images/lp-bsc.svg) no-repeat center center;
}

.menu__item:last-of-type i {
    background: url(../../images/dao.svg) no-repeat center center;
}
.menu__item--actived:last-of-type i{
    background: url(../../images/dao-blue.svg) no-repeat center center;
}
.menu__item--actived:last-of-type  .i-bsc{
    background: url(../../images/dao-bsc.svg) no-repeat center center;
}
.menu__item--actived:last-of-type  .i-eth{
    background: url(../../images/dao-eth.svg) no-repeat center center;
}
