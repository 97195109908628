.h-btns {
    width: 100%;
    margin-top: 20px;
    display: block;
}

.h-btns-content {
    margin: 0 20px;
    height: 44px;
    border-radius: 24px;
    background: rgba(255, 255, 255);
    display: flex;
}

.g-button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50%; */
    outline: none;
    border: none;
   
    border-radius: 22px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
}
.g-width{
    width: 50% !important;
}
.tab-heco{
    background-image: linear-gradient(270deg, #5eb0ff 0%, #3078ff 100%);
}
.tab-bsc{
    background-image: linear-gradient(270deg, #ffc62c 0%, #ffa954 100%)
}
.tab-eth{
    background-image: linear-gradient(270deg, #1165E4 2%, #233BB8 100%);
}
.nothing-button-heco {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    outline: none;
    border: none;
    background:#ffffff;
    border-radius: 22px;
    font-size: 14px;
    color: #3078ff;
    font-weight: bold;
    /* border:1px solid #3078ff; */
    margin:5px;
}
.nothing-button-bsc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    outline: none;
    border: none;
    background:#ffffff;
    border-radius: 22px;
    font-size: 14px;
    color: #ffa954;
    font-weight: bold;
    /* border:1px solid #3078ff; */
    margin:5px;
}
@media screen and (max-width: 900px) {
    .h-btns {
        display:block;
    }
}
@media screen and (min-width: 1200px) {
    .h-btns {
        display: none;
    }
}