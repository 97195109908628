.pools-main {
  max-width: 1200px;
  margin: 20px auto 0;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.pools__dialog-inners {
  box-sizing: border-box;
  min-width: 300px;
  background-color: #fff;
  border-radius: 16px;
  padding-top: 28px;
  font-size: 14px;
  padding: 16px 24px 20px;
  /* width: 90vw; */
  /* min-width: 90vw; */
}

.staking_lists {
  display: flex;
}

.pools__dialog__header__closeIsDia {
  position: absolute;
  right: -20px;
  top: -10px;
  width: 16px;
  height: 16px;
  background: url(../../../../assets/img/icon-close.svg) no-repeat center center;
  border: 10px solid transparent;
  cursor: pointer;
  z-index: 3;
}

.pools__dialog__header__closeIsDiaS {
  position: absolute;
  right: -20px;
  top: -10px;
  width: 16px;
  height: 16px;
  background: url(../../../../assets/img/icon-close.svg) no-repeat center center;
  border: 10px solid transparent;
  cursor: pointer;
  z-index: 3;
}

.staking_color {
  color: #000000 !important;
  margin-top: 20px;
}

.staking_close {
  right: 15px !important;
}

.staking_li_content {
  width: 33.3333333%;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  /* position: relative; */
  margin-bottom: 20px;
}

.staking_li_content>div {
  width: calc(100% - 15px);
  box-sizing: border-box;
  /* padding: 28px 40px; */
  border-radius: 16px;
  /* background-color: hsla(0, 0%, 100%, .7); */
  border: 1px solid #fff;
  position: relative;
}

.staking_li_content:nth-child(3n) {
  justify-content: flex-end;
}

.staking_li_content:nth-child(3n-1) {
  justify-content: center;
}

.staking_li_content-title {
  width: 100%;
  height: 370px;
  display: flex;
  justify-content: space-between;
  background: rgba(48, 120, 255, 0.08);
  border-radius: 16px;
}

.select_type-content {
  /* background: red; */
}

.select_type {
  line-height: 30px;
  position: relative;
  /* border: 1px solid #0f0d0c; */
  width: 50px;
  height: 30px;
  text-align: center;
  padding: 0 20px 0 10px;
  border-radius: 50px;
  margin-top: 10px;
  background: #F6F8FB;
}

.staking_title-li {
  width: 63px;
  /* height: 28px; */
  font-family: DINPro-Bold;
  font-size: 24px;
  /* color: #F7FAFF; */
  letter-spacing: 0;
  line-height: 70px;
  font-weight: 700;
  margin-left: 32px;
}

.pools__coin-logs {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  /* opacity: 0.3; */
  margin-right: 10px;
}

.pools__coin-logos {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-top: 20px;
  margin-right: 32px;
  /* opacity: 0.3; */
}

.staking_content {
  height: 310px;
  margin-top: -300px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  position: relative;
  /* z-index: 1; */
}

.staking_li_time {
  /* height: 50px; */
  display: flex;
  padding: 0 32px;
}

.staking_lists-jie {
  display: flex;
  /* margin-left: 25px; */
}

.staking_li_time-li {
  /* width: 44px; */
  height: 16px;
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  color: #FF7F07;
  letter-spacing: 0;
  text-align: center;
  line-height: 16px;
  font-weight: 600;
  border: 1px solid #FF7F07;
  border-radius: 5px;
  margin-top: 20px;
  margin-right: 10px;
  padding: 3px 8px;

  font-weight: bold;
  background-image: -webkit-linear-gradient(left, #FF7F07, #FFB611);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.staking_yield {
  padding: 0 32px;
  margin-top: 20px;
}

.bsc-staking_title {
  background: rgba(255, 163, 79, .06) !important;
}

.bsc-staking_data>div {
  background: rgba(255, 163, 79, .06) !important;
}

.bsc-staking__dialog__name {
  background: rgba(255, 163, 79, .06) !important;
}

.staking_yield-title {
  /* width: 36px; */
  height: 16px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #8391A8;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}

.staking_yield-data {
  /* width: 202px; */
  height: 28px;
  font-family: DINPro-Black;
  font-size: 24px;
  color: #00B595;
  letter-spacing: 0;
  /* text-align: right; */
  line-height: 28px;
  font-weight: 900;
}

.staking_data {
  padding: 0 32px;
  margin-top: 20px;
}

.staking_data>div {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  background: rgba(48, 120, 255, 0.08);
  border-radius: 16px;
  height: 82px;
}

.staking_data-li,
.staking_data-lis {
  margin-top: 20px;
}

.staking_data-li>div:nth-child(1) {
  width: 79px;
  height: 18px;
  font-family: DINPro-Bold;
  font-size: 16px;
  color: #131D32;
  letter-spacing: 0;
  /* text-align: right; */
  line-height: 18px;
  font-weight: 700;
}

.staking_data-lis>div:nth-child(1) {
  width: 79px;
  height: 18px;
  font-family: DINPro-Bold;
  font-size: 16px;
  color: #131D32;
  letter-spacing: 0;
  text-align: right;
  line-height: 18px;
  font-weight: 700;
}

.staking_data-li>div:nth-child(2) {
  height: 16px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #8391A8;
  letter-spacing: 0;
  text-align: left;
  line-height: 16px;
  font-weight: 400;
  margin-top: 8px;
}

.staking_data-lis>div:nth-child(2) {
  /* width: 80px; */
  height: 16px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #8391A8;
  letter-spacing: 0;
  text-align: right;
  line-height: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.staking_btn {
  margin-top: 20px;
  padding: 0 32px;
  position: relative;
  z-index: 1;
}



.staking_li_content-title-two {
  width: 100%;
  height: 130px;
  background: rgba(48, 120, 255, 0.08);
  border-radius: 16px 16px 0 0;
  position: relative;
  overflow: hidden;
}

.staking_title-two-top {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.pools__coin-logo-tow {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  /* margin-top: -5px;
  margin-right: -5px; */
}

.pools__coin-logo-right {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  /* opacity: 0.3; */
  margin-right: 10px;
  /* margin-top: 10px; */
}

.staking_title-two-bottom {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 50px;
  margin-top: 20px;
}

.staking_content-two {
  /* height: 315px; */
  border-radius: 16px;
  margin-top: -10px;
  background: #ffffff;
  position: relative;
}

.staking_two_id {
  font-family: DINPro-Regular;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  text-align: center;
  /* line-height: 30px; */
  font-weight: 400;
  margin-top: 10px;
  padding-bottom: 16px;
}

.staking_title-li-two {
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
}

.staking_title-li-two>div:nth-child(1) {
  /* width: 82px; */
  height: 28px;
  font-family: DINPro-Bold;
  font-size: 24px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 700;
}

.staking_two_name {
  font-family: DINPro-Bold;
  font-size: 24px;
  color: #131D32;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 700;
  margin-left: 10px;
}

.staking_top_three {
  /* width: 24px; */
  height: 16px;
  font-family: PingFangTC-Semibold;
  font-size: 12px;
  color: #FF7F07;
  letter-spacing: 0;
  text-align: center;
  line-height: 16px;
  font-weight: 600;
  border: 1px solid #FF7F07;
  border-radius: 4px;
  padding: 2px 8px;
  margin-top: 2px;
  margin-left: 10px;
  /* cursor: pointer; */
}

.progress_bar {
  margin-left: 20px;
}

.progress_bar>div:nth-child(1) {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #8391A8;
  letter-spacing: 0;
  /* line-height: 30px; */
  font-weight: 400;
}

.progress_bar>div:nth-child(2) {
  width: 150px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  margin-top: 8px;
}

.staking_biao {
  height: 20px;
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  color: #FF7F07;
  letter-spacing: 0;
  text-align: center;
  /* line-height: 16px; */
  font-weight: 600;
  border: 1px solid #FF7F07;
  padding: 0 8px;
  margin-top: 2px;
  border-radius: 5px;
  line-height: 20px;
  margin-left: 5px;
  font-weight: bold;
  background-image: -webkit-linear-gradient(left, #FF7F07, #FFB611);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.days_remaining {
  width: 60px;
  height: 36px;
  background: rgba(255, 255, 255, 0.90);
  border: 1px solid #FFFFFF;
  border-radius: 100px 0px 0px 100px;
  /* position: absolute;
  right:10px; */
  text-align: center;
  color: #3078FF;
  font-family: DINPro-Bold;
  margin-top: -10px;
}

.btn_hui {
  /* background: #eeeeee !important; */
  color: #ffffff !important;
  border: none !important;
  background: #C5C9D5 !important;
}

.btn_hui:hover {
  background: #C5C9D5 !important;
}

.hot_title {
  font-size: 14px !important;
  color: #FF7F07;
  font-weight: bold;
  background-image: -webkit-linear-gradient(left, #FF7F07, #FFB611);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blue_title {
  font-size: 14px !important;
  color: #3078FF;
}

.days_remaining>span {
  font-size: 25px;
}

.staking_two-deposits {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 0;
  height: 40px;
  line-height: 40px;
  position: relative;
  z-index: 1;
}

.staking_two-deposit {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
}

.staking_two-deposit>div:nth-child(1),
.staking_two-deposits>div:nth-child(1) {
  /* width: 100px; */
  /* height: 16px; */
  font-family: DINPro-Medium;
  font-size: 12px;
  color: #8391A8;
  letter-spacing: 0;
  font-weight: 500;
}

.staking_two-deposit>div:nth-child(2),
.staking_two-deposits>div:nth-child(2) {
  /* width: 42px; */
  height: 18px;
  font-family: DINPro-Bold;
  font-size: 14px;
  color: #131D32;
  letter-spacing: 0;
  text-align: right;
  font-weight: 700;
}

.staking_two-slide {
  /* width: 198px;
  border: 1px solid #EDEFF2;
  margin: auto */
}

.staking_two-profit {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 40px;
  margin-top: 20px;
}

.staking_two-profit>div:nth-child(1) {
  /* width: 100px; */
  height: 16px;
  font-family: DINPro-Medium;
  font-size: 12px;
  color: #8391A8;
  letter-spacing: 0;
  line-height: 40px;
  font-weight: 500;
}

.staking_two-profit>div:nth-child(2) {
  width: 42px;
  height: 18px;
  font-family: DINPro-Bold;
  font-size: 14px;
  color: #131D32;
  letter-spacing: 0;
  text-align: right;
  line-height: 40px;
  font-weight: 700;
}

.staking_two-profit-new {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 40px;
}

.staking_two-profit-new>div:nth-child(1) {
  /* width: 100px; */
  height: 16px;
  font-family: DINPro-Medium;
  font-size: 12px;
  color: #8391A8;
  letter-spacing: 0;
  line-height: 40px;
  font-weight: 500;
}

.staking_two-profit-new>div:nth-child(2) {
  width: 42px;
  height: 18px;
  font-family: DINPro-Bold;
  font-size: 14px;
  color: #131D32;
  letter-spacing: 0;
  text-align: right;
  line-height: 40px;
  font-weight: 700;
}

.staking_two-btn {
  position: relative;
  z-index: 1;
  margin-top: 10px;
}

.staking_tip {
  max-width: 1200px;
  display: flex;
  padding: 0 10px;
  margin: 20px auto 0;
}

.staking_tip>div:nth-child(1) {
  width: 14px;
  height: 14px;
  background: #FF7F07;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  line-height: 14px;
  margin-top: 3px;
}

.staking_tip>div:nth-child(2) {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #FF7F07;
  letter-spacing: 0;
  font-weight: 400;
  margin-left: 10px;
}

.staking_two_btn {
  margin: 10px auto;
}

.staking_two_btns {
  width: 90% !important;
  margin: 10px 5%;
}

/* .staking_two_btn:nth-child(2) {
  margin: 20px auto;
} */

.staking_dialog-deposit {
  width: 600px;
  /* height: 660px; */
  background: #FFFFFF;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}

.staking__dialog__header {
  font-size: 20px;
  /* color: #ffffff; */
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
  position: relative;
}

.staking__dialog__headers {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.staking__dialog__header__close {
  position: absolute;
  right: -20px;
  /* top: 10px; */
  width: 16px;
  height: 16px;
  background: url(/static/media/icon-close.1ed26250.svg) no-repeat center center;
  border: 10px solid transparent;
  cursor: pointer;
  z-index: 3;
}

.staking__dialog__header__return {
  position: absolute;
  right: 500px;
  /* top: 10px; */
  width: 16px;
  height: 16px;
  background: url(/static/media/icon-close.1ed26250.svg) no-repeat center center;
  border: 10px solid transparent;
  cursor: pointer;
  z-index: 3;
}

.staking__dialog__header__closeone {
  position: absolute;
  right: -20px;
  /* top: 10px; */
  width: 16px;
  height: 16px;
  background: url(/static/media/icon-close.1ed26250.svg) no-repeat center center;
  border: 10px solid transparent;
  cursor: pointer;
  z-index: 3;
}

.staking__dialog__header__closes {
  position: absolute;
  right: 20px;
  /* top: 10px; */
  width: 16px;
  height: 16px;
  background: url(/static/media/icon-close.1ed26250.svg) no-repeat center center;
  border: 10px solid transparent;
  cursor: pointer;
  z-index: 3;
}

.staking__dialog__fields {
  padding: 0 40px;
}

.staking__dialog__name {
  /* display: flex;
  justify-content: space-between; */
  /* margin-top: 20px; */
  height: 200px;
  background: rgba(48, 120, 255, .08);
  padding: 20px 40px 0;
}

.staking__dialog__middle {
  width: 520px;
  position: absolute;
  left: 40px;
  top: 140px;
}

.staking__dialog__middle>div {
  background: #ffffff;
  padding: 20px 30px;
  border-radius: 16px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
}

.staking__dialog__botton {
  padding: 0 70px;
  margin-top: 145px;

}

.staking_width_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.staking__dialog__name-li {
  display: flex;
}

.staking__dialog__name-lis {
  display: flex;
}

.staking__name_title {
  /* width: 36px; */
  /* height: 28px; */
  font-family: DINPro-Bold;
  font-size: 24px;
  /* color: #ffffff; */
  letter-spacing: 0;
  line-height: 48px;
  font-weight: 700;
}

.staking__name_title-1 {
  color: #000000;
  margin-left: 10px;
}

.staking__dialog__name-mun {
  display: flex;
  /* margin-left: 10px; */
}

.staking__dialog__name-mun>div:nth-child(1) {
  /* width: 47px; */
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 48px;
  font-weight: 400;
}

.staking__dialog__name-mun>div:nth-child(2) {
  /* width: 63px; */
  font-family: DINPro-Bold;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: right;
  line-height: 48px;
  font-weight: 700;
  margin-left: 5px;
}

.staking__dialog__time {
  display: flex;
  margin-top: 10px;
}

.staking__dialog__times {
  padding-top: 20px;
  width: 100%;
  display: flex;
  overflow-x: scroll;
}

.staking__time {
  width: 40px;
  height: 30px;
  /* background-image: linear-gradient(270deg, #FFB611 0%, #FF7F07 99%); */
  background: rgba(255, 171, 87, 0.05);
  ;
  border-radius: 16px;
  text-align: center;
  line-height: 30px;
  color: #FFAB57;
  position: relative;
  margin-right: 55px;
  padding: 0 15px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  border: 1px solid #FFAB57;
}

.staking__time:hover {
  background: #FFAB57;
  color: #ffffff;
  padding: 0 15px;
}
.staking__time_token{
  width: 40px;
  height: 30px;
  /* background-image: linear-gradient(270deg, #FFB611 0%, #FF7F07 99%); */
  background: rgba(255, 171, 87, 0.05);
  ;
  border-radius: 16px;
  text-align: center;
  line-height: 30px;
  color: #FFAB57;
  position: relative;
  margin-right: 55px;
  padding: 0 15px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  border: 1px solid #FFAB57;
}
.staking__times {
  background: linear-gradient(270deg, #FFB611 0%, #FF7F07 99%);
  color: #ffffff;
  width: 40px;
  height: 32px;
  /* background-image: linear-gradient(270deg, #FFB611 0%, #FF7F07 99%); */
  border-radius: 16px;
  text-align: center;
  line-height: 32px;
  position: relative;
  margin-right: 55px;
  /* padding: 0 10px; */
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  padding: 0 16px;
}

.staking__time-percentage {
  position: absolute;
  /* top: -15px; */
  left: 60px;
  bottom: 25px;
  /* right: -45px; */
  /* width: 40px; */
  height: 20px;
  line-height: 20px;
  background-image: linear-gradient(270deg, #FFAB6E 0%, #FF4F83 100%);
  border-radius: 10px 10px 10px 0px;
  color: #ffffff;
  padding: 2px 5px;
}

.bsc_staking__time-percentage {
  /* right: -45px ; */
}

.bsc_dot {
  /* right: -45px; */
}

.staking__dialog__apy {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.staking__dialog__name-right {
  display: flex;
  font-family: DINPro-Bold;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  text-align: right;
  line-height: 49px;
  font-weight: 700;
}

.staking__dialog__apy>div:nth-child(1) {
  /* width: 26px; */
  font-family: DINPro-Medium;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  font-weight: 500;
  position: relative;
  margin-top: 5px;
}

.staking__dialog__apy>div:nth-child(2) {
  /* width: 80px; */
  height: 24px;
  font-family: DINPro-Bold;
  font-size: 24px;
  color: #00B595;
  letter-spacing: 0;
  text-align: right;
  font-weight: 700;
}

.staking__dialog__progress-bar {
  margin-top: 20px;
}

.staking__progress-bar-title {
  display: flex;
  justify-content: space-between;
  font-family: DINPro-Medium;
  font-size: 12px;
  /* color: #8391A8; */
  letter-spacing: 0;
  font-weight: 500;
}

.staking__progress-bar-title>span {
  width: 100px;
  text-align: center;
}

.staking__progress-bar-title>span:nth-child(1) {
  text-align: left;
}

.staking__progress-bar-title>span:nth-child(3) {
  text-align: right;
}

.staking__progress-bar {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.staking__progress-bar>div:nth-child(1),
.staking__progress-bar>div:nth-child(5) {
  /* width: 10px;
  height: 10px; */
  /* border-radius: 50%;
  background: #8391A8; */
  margin-top: -3px;
}

.staking__progress-bar>div:nth-child(3) {
  margin-top: -6px;
}

.staking__progress-bar>div:nth-child(2),
.staking__progress-bar>div:nth-child(4) {
  width: 40%;
  height: 2px;
  background: #eeeeee;
  margin-top: 4px;
}

.staking__dialog__day {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.staking__dialog__day>div {
  width: 100px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 12px;
  color: #131D32;
  letter-spacing: 0;
  font-weight: 700;
  text-align: center;
  /* line-height: 30px; */
}

.staking__dialog__day>div:nth-child(1) {
  text-align: left;
}

.staking__dialog__day>div:nth-child(3) {
  text-align: right;
}

.staking__dialog__survivable {
  /* margin-top: 30px; */
}

.staking__dialog__survivable {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  /* margin-top: 30px; */
}

.pools__dialog__input-staking {
  margin-top: 20px;
}

.staking__dialog__balance {
  display: flex;
  justify-content: space-between;
  /* line-height: 40px; */
  margin-top: 10px;
}

.staking__dialog__balance {
  margin-bottom: 10px;
}

.staking__dialog__survivable>div:nth-child(1) {
  font-family: DINPro-Medium;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  font-weight: 500;
}

.staking__dialog__survivable>div:nth-child(2) {
  font-family: DINPro-Bold;
  font-size: 16px;
  color: #131D32;
  letter-spacing: 0;
  text-align: right;
  font-weight: 700;
}

.staking__dialog__balance>div:nth-child(1) {
  font-family: DINPro-Medium;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  font-weight: 500;
}

.staking__dialog__balance>div:nth-child(2) {
  font-family: DINPro-Bold;
  font-size: 16px;
  color: #131D32;
  letter-spacing: 0;
  text-align: right;
  font-weight: 700;
}

.staking__dialog__top {
  height: 36px;
  font-family: MicrosoftYaHei;
  font-size: 13px;
  color: #FFAB57;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  margin-top: 10px;
}

.staking__dialog__btn {
  margin: 30px 0 50px;
}

.staking_dialog-strategy {
  /* width: 1000px; */
  /* height: 700px; */
  background: #FFFFFF;
  border-radius: 16px;
}

.staking_strategy_name {
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.staking_strategy_name-li>div:nth-child(1) {
  height: 28px;
  font-family: DINPro-Bold;
  font-size: 24px;
  color: #131D32;
  letter-spacing: 0;
  text-align: right;
  line-height: 28px;
  font-weight: 700;
}

.staking_strategy_name-li>div:nth-child(2) {
  height: 16px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #8391A8;
  letter-spacing: 0;
  text-align: right;
  line-height: 16px;
  font-weight: 400;
}

.tab__containers {
  height: 42px;
  background: #F6F8FB;
  border-radius: 195px;
  padding: 2px;
  display: flex;
}

.staking_strategy_content {
  /* height: 450px; */
  max-height: 450px;
  padding: 0 60px;
  margin: auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: scroll;
}

.staking_strategy_contents {
  /* height: 450px; */
  /* max-height: 450px; */
  padding: 0 60px;
  margin: auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: scroll;
}

.strategy_li-one_content {
  padding: 0 20px;
}

.staking_strategy_content-li {
  width: 410px;

  background: #F6F8FB;
  border-radius: 16px;
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
}

.staking_strategy_content-lis {
  width: 410px;
  /* height: 360px; */
  background: #F6F8FB;
  border-radius: 16px;
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
}

.staking_strategy_content-li:nth-child(2n),
.staking_strategy_content-lis:nth-child(2n) {
  margin-left: 20px;
}

.staking_strategy_content-li>div:nth-child(2),
.staking_strategy_content-lis>div:nth-child(2) {
  padding: 0 20px 0;
}

.strategy_li-one {
  width: 154px;
  font-family: DINPro-Bold;
  font-size: 24px;
  color: #ffffff;
  /* letter-spacing: 0; */
  /* font-weight: 700; */
  background: linear-gradient(270deg, #5EB0FF 0%, #3078FF 100%);
  padding: 10px 20px;
  border-radius: 16px 0 16px 0;
  cursor: pointer;
  display: flex;
}

.strategy_li-two,
.strategy_li-three,
.strategy_li-four,
.strategy_li-six,
.strategy_li-seven,
.strategy_li-eight,
.strategy_li-nine,
.strategy_li-ten {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
}

.hot {
  color: #FF7F07;
  font-weight: bold;
  background-image: -webkit-linear-gradient(left, #FF7F07, #FFB611);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.strategy_li-two>div:nth-child(1),
.strategy_li-three>div:nth-child(1),
.strategy_li-four>div:nth-child(1),
.strategy_li-six>div:nth-child(1),
.strategy_li-seven>div:nth-child(1),
.strategy_li-eight>div:nth-child(1),
.strategy_li-nine>div:nth-child(1),
.strategy_li-ten>div:nth-child(1) {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #8391A8;
  letter-spacing: 0;
  font-weight: 400;
}

.strategy_li-two>div:nth-child(2),
.strategy_li-three>div:nth-child(2),
.strategy_li-six>div:nth-child(2),
.strategy_li-seven>div:nth-child(2),
.strategy_li-eight>div:nth-child(2),
.strategy_li-nine>div:nth-child(2),
.strategy_li-ten>div:nth-child(2) {
  font-family: DINPro-Bold;
  font-size: 14px;
  color: #131D32;
  letter-spacing: 0;
  text-align: right;
  font-weight: 700;
}

.strategy_li-ten>div:nth-child(2) {
  display: flex;
}

.pools__coin-logo-ces {
  margin-top: 2px;
  margin-right: 5px;
}

.strategy_li-four>div:nth-child(2) {
  width: 100px;
  font-family: DINPro-Bold;
  font-size: 16px;
  color: #357DF9;
  letter-spacing: 0;
  text-align: right;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.pools__mao-logo__wraps {
  position: absolute;
  width: 80px;
  height: 80px;
  right: 0;
  bottom: 0;
}

.pools__mao-logos {
  width: 100%;
  height: 100%;
  opacity: 0.3;
  filter: blur(50px);
}

.strategy_li-five {
  width: 370px;
  /* border: 1px solid #EDEFF2; */
  margin: 20px auto;
}

.staking_dialog-extract {
  width: 600px;
  background: #FFFFFF;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.staking_extract_top {
  height: 160px;
  background: rgba(48, 120, 255, .08);
  padding: 20px 40px 0;
}

.staking_extract_tops {
  height: 140px;
  background: rgba(48, 120, 255, .08);
  padding: 20px 40px 0;
}

.staking_extract_name {
  display: flex;
  justify-content: space-between;
  /* padding: 0 40px; */
  /* line-height: 48px;*/
  margin-top: 20px;
}

.staking_extract_name>div:nth-child(1) {}

.staking_extract_middle {
  width: 520px;
  position: absolute;
  left: 40px;
  top: 110px;
}

.three_day {
  height: 20px;
  border: 1px solid #FF7F07;
  color: #FF7F07;
  border-radius: 4px;
  line-height: 20px;
  margin-top: 13px;
  margin-left: 10px;
  padding: 0 8px;
  font-weight: bold;
  background-image: -webkit-linear-gradient(left, #FF7F07, #FFB611);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.day_btn {
  height: 20px;
  border: 1px solid #FF7F07;
  color: #FF7F07;
  border-radius: 4px;
  line-height: 20px;
  margin-top: 13px;
  margin-left: 10px;
  padding: 0 5px;
  cursor: pointer;
}

.staking_extract_name-right {
  font-family: DINPro-Medium;
  font-size: 16px;
  color: #8391A8;
  letter-spacing: 0;
  text-align: right;
  line-height: 49px;
  font-weight: 500;
}

.staking_extract_name-li {
  font-family: DINPro-Regular;
  font-size: 12px;
  color: #8391A8;
  /* letter-spacing: 0; */
  /* text-align: right; */
  /* font-weight: 400; */
}

.staking_extract_name-li>:nth-child(1) {
  margin-top: 10px;
}

.staking_extract_name-li>div {
  color: #ffffff;
}

.staking_extract_time {
  padding: 20px 30px;
  border-radius: 16px;
  background: #FFFFFF;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
  margin-top: 30px;
}

.time_top {
  display: flex;
  justify-content: space-between;
  font-family: MicrosoftYaHei-Bold;
  font-size: 12px;
  /* color: #8391A8; */
  letter-spacing: 0;
  font-weight: 400;
  /* margin-top: 10px; */
}

.time_top-one {
  display: flex;
}

.staking_extract_botton {
  padding: 0 70px;
  margin-top: 130px;
}

.time_bottom,
.time_bottoms {
  display: flex;
  justify-content: space-between;
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #131D32;
  letter-spacing: 0;
  font-weight: 700;
  /* margin-top: 10px; */
}

.time_middle {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.time_middle>div:nth-child(1),
.time_middle>div:nth-child(3),
.time_middle>div:nth-child(5) {
  /* width: 10px;
  height: 10px;
  background: #EDEFF2;
  border-radius: 50%; */
  margin-top: -3px;
}

.time_middle>div:nth-child(2),
.time_middle>div:nth-child(4) {
  width: 187px;
  height: 2px;
  background: #EDEFF2;
  margin-top: 5px;
}

.staking_extract_apy {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
}

.staking_extract_apy>div:nth-child(1) {
  font-family: DINPro-Medium;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  font-weight: 500;
  position: relative;
}

.staking_extract_apy>div:nth-child(2) {
  font-family: DINPro-Bold;
  font-size: 24px;
  color: #00B595;
  letter-spacing: 0;
  text-align: right;
  font-weight: 700;
}

.staking_extract_cun {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}

.staking_extract_cun>div:nth-child(1) {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  font-weight: 400;
}

.staking_extract_cun>div:nth-child(2) {
  font-family: DINPro-Bold;
  font-size: 16px;
  color: #131D32;
  letter-spacing: 0;
  text-align: right;
  font-weight: 700;
}

.staking_extract_input {}

.pools__dialog__extract-all {
  right: 20px !important;
}

.staking_extract_tip {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #FFAB57;
  letter-spacing: 0;
  font-weight: 400;
  margin-top: 10px;
}

.staking_extract_btn {
  margin: 30px 0 50px;
}

.staking_extract_wei>div {
  height: 100px;
  border-radius: 16px;
  margin: 10px auto;
  text-align: center;
  background: #F6F8FB;
  padding-top: 15px;
}

.staking_extract_wei>div>div:nth-child(1) {
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  color: #131D32;
  letter-spacing: 0;
  font-weight: 700;
  line-height: 20px;
  margin-top: 25px;
}

.staking_extract_wei>div>div:nth-child(2) {
  font-family: DINPro-Bold;
  font-size: 12px;
  color: #FFAB57;
  letter-spacing: 0;
  font-weight: 700;
  line-height: 20px;
  background-image: -webkit-linear-gradient(left, #FF7F07, #FFB611);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.staking_dialog_all-extract {
  width: 600px;
  height: 428px;
  background: #FFFFFF;
  border-radius: 16px;
}

.staking_all-extract-content>div {
  height: 242px;
  background: rgba(255, 163, 79, 0.08);
  border-radius: 16px;

  overflow: hidden
}

.extract-title {
  width: 130px;
  height: 40px;
  background-image: linear-gradient(205deg, #FFD087 0%, #FFA34F 95%);
  border-radius: 0px 100px 100px 0px;
  line-height: 40px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
}

.extract-content_top,
.extract-content_buttom {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  margin-top: 20px;
}

.staking_two-deposits_content {
  display: flex;
}

.extract-content_li>div:nth-child(1) {
  width: 71px;
  font-family: DINPro-Bold;
  font-size: 20px;
  color: #131D32;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 700;
}

.extract-content_li>div:nth-child(2) {
  width: 31px;
  font-family: DINPro-Medium;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 500;
}

.extract_btn {
  margin-top: 30px;
  padding: 0 80px;
}

.search_option_one {
  width: 28px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  line-height: 50px;
  margin-right: 20px;
  margin-left: 20px;
}

.search_option_two {
  width: 120px;
  height: 36px;
  background: #F6F8FB;
  border: 1px solid #EDEFF2;
  border-radius: 22px;
  text-align: center;
  font-family: DINPro-Medium;
  font-size: 14px;
  color: #131D32;
  letter-spacing: 0;
  font-weight: 400;
  margin-top: 8px;
}

.position_content {
  width: 520px;
  background: rgb(184, 156, 161);
  padding: 30px 0;
  /* baco */
}

.staking_li_content-two {
  width: 25%;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 30px;
  position: relative;
}

.staking_li_content-two>div {
  width: calc(100% - 15px);
  box-sizing: border-box;
  /* padding: 28px 40px; */
  border-radius: 16px;
  /* background-color: hsla(0, 0%, 100%, .7); */
  border: 1px solid #fff;
  position: relative;
}



.staking_li_content-two:nth-child(4n-1) {
  justify-content: center;
}

.staking_li_content-two:nth-child(4n-2) {
  justify-content: center;
}

.unlock_time {
  margin-top: 10px;
}

.unlock_time_one {
  display: flex;
}

.unlock_time_one-left,
.unlock_time_two-left,
.unlock_time_three-left {
  width: 10%;
}

.unlock_time_one-left>div:nth-child(1) {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 2px solid #3078FF;
  margin-top: 10px;
}

.unlock_time_one-left>div:nth-child(2),
.unlock_time_two-left>div:nth-child(2),
.unlock_time_three-left>div:nth-child(2) {
  width: 2px;
  height: 35px;
  background: #000000;
  margin-top: 10px;
  margin-left: 3px;
}

.unlock_time_one-middle,
.unlock_time_two-middle,
.unlock_time_three-middle {
  width: 70%;
}

.unlock_time_one-middle>div:nth-child(1),
.unlock_time_two-middle>div:nth-child(1),
.unlock_time_two-middle>div:nth-child(3),
.unlock_time_three-middle>div:nth-child(1) {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 400;
}

.unlock_time_one-middle>div:nth-child(2),
.unlock_time_two-middle>div:nth-child(2),
.unlock_time_three-middle>div:nth-child(2) {
  font-family: DINPro-Bold;
  font-size: 16px;
  color: #131D32;
  letter-spacing: 0;
  /* text-align: right; */
  line-height: 25px;
  font-weight: 700;
}

.unlock_time_one-right,
.unlock_time_two-right,
.unlock_time_three-right {
  width: 20%;
  margin-top: 10px;
}

.unlock_one-right_btn,
.unlock_two-right_btn,
.unlock_three-right_btn {
  height: 32px !important;
}

.unlock_time_two,
.unlock_time_three {
  display: flex;
  margin-top: 10px;
}

.unlock_time_two-left>div:nth-child(1) {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 2px solid #FFC900;
  margin-top: 10px;
}

.unlock_time_three-left>div:nth-child(1) {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 2px solid #00CD2D;
  margin-top: 10px;
}

.unlock_time_three {
  margin-bottom: 20px;
}

.unlock_middle_title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #131D32;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}

.unlock_middle_titles {
  display: flex;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 500;
  margin-top: 10px;
}

.unlock_input {
  height: 94px;
}

.unlock_input>input {
  height: 100%;
}

.unlock_input>button {
  margin-top: 25px;
}

.staking_extract_bottons {
  padding: 0 70px;
  margin-top: 190px;
}

.unlock_buttom_tip {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #FFAB57;
  letter-spacing: 0;
  text-align: center;
  line-height: 30px;
  font-weight: 400;
}

.deposit-huo-five {
  margin-left: -45px;
}

.unlocking_list {
  display: flex;
  justify-content: space-between;
}

.bsc_staking_li_content-title-two {
  background: rgba(255, 163, 79, .06);
}

.staking_extract_botton_un {
  padding: 0 70px;
  margin-top: 190px;
}

.pools__coin-logo-ce {
  margin-right: 5px;
}

.cang_tab {
  display: flex;
}

.tab_list {
  display: flex;
  margin-left: 20px;
}

.tab_list-title {
  line-height: 50px;
}

.search_options {
  display: flex;
}

.staking_ce_tip {
  padding: 0 60px;
  margin: 16px 0;
  font-size: 12px;
  color: #FFAB57;
}

@media screen and (min-width: 1200px) {
  .time_bottoms>div:nth-child(2) {
    margin-left: -50px;
  }

  .staking_strategy_content {
    /* width: 900px; */
  }

  .staking_li_content-two:nth-child(4n) {
    justify-content: flex-end;
  }

  .staking_all-extract-content {
    width: 440px;
    margin: auto;
  }

  .staking_extract_wei>div {
    width: 480px;
    background: #F6F8FB;
  }

  .staking_strategy_content-li {
    margin-bottom: 20px;
    /* height: 422px; */
  }

  .staking_strategy_content-lis {
    margin-bottom: 20px;
  }
  .optim_name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
  }
}

@media screen and (max-width: 900px) {
  .optim_name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
  }
  .tab__containers {
    height: 38px !important;
    padding: 0 !important;
    width: 100% !important;
    border-radius: 16px 16px 0 0 !important;
    margin-bottom: 12px !important;
    background-color: #edeff2 !important;
    overflow-x: scroll !important;
  }

  .progress_bar>div:nth-child(2) {
    width: 200px;
  }

  .drop__info-item-texts>span {
    margin-left: 5px !important;
    position: relative !important;
  }

  .staking_ce_tip {
    padding: 0 20px;
  }

  .time_bottoms>div:nth-child(2) {
    margin-left: -50px;
  }

  .staking_strategy_content-li:nth-child(2n-1),
  .staking_strategy_content-lis:nth-child(2n-1) {
    margin-left: 0;
  }

  .strategy_li-one {
    font-size: 18px;
  }

  .staking__time {
    width: 40px;
    height: 30px;
    padding: 0 10px !important;
    flex-shrink: 0;
    margin-right: 50px !important;
  }
  .staking__time_token {
    width: 40px;
    height: 30px;
    padding: 0 10px !important;
    flex-shrink: 0;
    margin-right: 50px !important;
  }
  .staking__times {
    width: 40px;
    height: 32px;
    padding: 0 10px !important;
    flex-shrink: 0;
    margin-right: 50px !important;
  }

  /* .staking__time:last-child{
    margin-bottom: 0;
  }
  .staking__times:last-child{
    margin-bottom: 0;
  } */
  .staking__time-percentage {
    /* right: -60px; */
    left: 50px;
    font-size: 12px;
    /* display: none; */
  }

  .pools__dialog__extract-all {
    right: 10px !important;
  }

  .staking_extract_btn {
    margin: 20px 0;
  }

  .staking_extract_tip {
    font-size: 10px;
  }

  .staking_extract_time {
    padding: 20px;
  }

  .pools__coin-logos {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 10px;
    margin-right: 20px;

  }

  .staking_li_content-title {
    height: 350px;
  }

  .staking_content {
    margin-top: -300px;
  }

  .staking_title-li {
    line-height: 50px;
  }

  .staking__dialog__top {
    font-size: 10px;
  }

  .staking__dialog__survivable>div:nth-child(1) {
    font-size: 12px;
  }

  .staking__dialog__survivable>div:nth-child(2) {
    font-size: 14px;
  }

  .staking__dialog__balance>div:nth-child(1) {
    font-size: 12px;
  }

  .staking__dialog__balance>div:nth-child(2) {
    font-size: 14px;
  }

  .staking__dialog__middle>div {
    background: #ffffff;
    padding: 20px 20px;
    border-radius: 16px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
  }

  .staking_strategy_content-li:nth-child(2n),
  .staking_strategy_content-lis:nth-child(2n) {
    margin-left: 0;
  }

  .staking_li_content-title-two {
    height: 350px;
  }

  .staking_content-two {
    /* height: 250px; */
    border-radius: 16px;
    margin-top: -240px;
    background: #ffffff;
    position: relative;
  }

  .staking_extract_top {
    height: 200px;
    background: rgba(48, 120, 255, .08);
    padding: 20px 20px 0;
  }

  .staking__dialog__header__closeone {
    right: 0px;
  }

  .pools__coin-logo-right {
    margin-right: 10px;
    margin-top: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .staking_extract_botton {
    padding: 0 20px;
    margin-top: 80px;
  }

  .staking_li_content>div {
    width: 100%;
    position: relative;
  }

  .staking__dialog__header__close {
    right: -10px;
  }

  .staking__dialog__header__close-H5 {
    position: absolute;
    /* top: 10px; */
    width: 16px;
    height: 16px;
    background: url(/static/media/icon-close.1ed26250.svg) no-repeat center center;
    border: 10px solid transparent;
    cursor: pointer;
    z-index: 3;
    right: 10px;
    top: 5px;
  }

  .staking__dialog__name {
    padding: 20px 20px 0;
  }

  .pools__coin-logs {
    margin-right: 10px;
    margin-top: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .search_option_two {
    margin-left: 20px;
  }

  .staking_li_content {
    width: 100%;
    padding: 0 15px;
  }

  .staking_dialog-deposit {
    width: 100%;
    border-radius: 16px 16px 0 0;
    position: absolute;
    bottom: 0;
  }

  .staking_dialog-strategy {
    width: 100%;
    border-radius: 16px 16px 0 0;
    position: absolute;
    bottom: 0;
  }

  .staking_strategy_name {
    padding: 0 20px;
  }

  .staking_strategy_content {
    height: 400px;
    padding: 0;
    overflow-y: scroll;
    margin-top: 10px;
    width: 100%;
  }

  .staking_strategy_content-li,
  .staking_strategy_content-lis {
    width: 100%;
    padding: 0 20px;
    background: #ffffff;
    margin-bottom: 20px;

  }

  .staking_strategy_content-li>div,
  .staking_strategy_content-lis>div {
    background: #F6F8FB;
    border-radius: 16px;
  }

  .strategy_li-five {
    width: 90%;
  }

  .staking_li_content-two {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
    justify-content: center;
  }

  .staking_two-btn-group {
    /* flex-direction: inherit; */
  }

  .staking_two_btn:nth-child(1) {
    margin: 10px auto;
  }

  .staking_dialog_all-extract {
    width: 100%;
    border-radius: 16px 16px 0 0;
    position: absolute;
    bottom: 0;
  }

  .staking_all-extract-content {
    /* width: 100%; */
    padding: 0 20px;
  }

  .extract_btn {
    padding: 0 20px;
  }

  .staking_dialog-extract {
    width: 100%;
    border-radius: 16px 16px 0 0;
    position: absolute;
    bottom: 0;
  }

  .time_middle>div:nth-child(2),
  .time_middle>div:nth-child(4) {
    width: 40%;
  }

  .staking_extract_wei {
    width: 100%;
  }

  .staking_extract_wei>div {
    /* width: 100%; */
    /* margin:20px; */
    background: #F6F8FB;
  }

  .staking_extract_middle {
    width: 100%;
    left: 0;
    top: 100px
  }

  .staking_extract_middle>div {
    padding: 0 20px;
  }

  .staking__dialog__botton {
    padding: 0 20px;
    margin-top: 125px;
  }

  .staking__dialog__bottons {
    padding: 0 20px;
    margin-top: 130px;
  }

  .staking__dialog__middle {
    width: 90%;
    left: 0;
    padding: 0 20px;
    top: 120px;
  }

  .staking__dialog__btn {
    margin: 20px 0;
  }
  ::-webkit-scrollbar{
    display: none;
  } 
}