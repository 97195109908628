.home__carousel {
  margin-bottom: 20px;
  border-radius: 16px;
  overflow: hidden;
  min-height: 140px;
}

.home__carousel .carousel-slider {
  min-height: 140px;
  background-color: #fff;
}

.carousel .control-dots .dot {
  margin: 0 5px !important;
}

.project__apy {
  margin-right: 52px;
}

.home__common-container a {
  display: block;
}
.wallet-disconnect{
  color: #3078FF;
  margin-left: 10px;
  cursor: pointer;
}
.project_empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 16px;
  color: #00B595;
  background-color: #fff;
}

.icon-li {
  display: flex;
  text-align: center;
}

.home-icon-ask {
  background: url(../../assets/img/icon-zong.svg) no-repeat center center;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: contain;
  margin: 0 5px 0 10px;
  /* margin-top: 8px; */
  vertical-align: center;
}

.home-icon-asks {
  background: url(../../assets/img/icon-fen.svg) no-repeat center center;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: contain;
  margin: 0 5px 0 10px;
  /* margin-top: 8px; */
  vertical-align: center;
}

.drop__info {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 98px; */
  background-color: #F1F9FE;
  margin-top: 20px;
  border-radius: 8px;
  flex-wrap: wrap;
}

.drop__info-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16%;
  flex-direction: column;
  height: 98px;
}

.drop__info-item-title {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  /* position: relative; */
}

.drop__info-item-text {
  font-family: DINPro-Bold;
  font-size: 18px;
  color: #131D32;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  margin-top: 10px;
  position: relative;
  width: 100%;
}

.drop__info-item-text span {
  font-size: 14px;
  color: #3078FF;
  margin-left: 12px;
  line-height: 22px;
  position: absolute;
  cursor: pointer;
}

.drop__info-item-title span {
  font-size: 14px;
  color: #3078FF;
  margin-left: 4px;
  line-height: 22px;
}

.drop__info-item-text span.bsc-span,
.drop__info-item-title span.bsc-span {
  color: #FFA34F;
}

.drop__info-item-text span.eth-span,
.drop__info-item-title span.eth-span {
  color: #233BB8;
}

.profit__left-content .bsc-span {
  color: #FFA34F;
}

.profit__left-content .heco-span {
  color: #ffffff;
}

.profit__left-content .eth-span {
  color: #ffffff;
}

.profit__left-content .eth-span {
  color: #ffffff;
}

.heco-home_li {
  background-image: linear-gradient(270deg, #3078FF 0%, #5DAFFF 100%);
}

.heco-home_li-span {
  color: #ffffff !important;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  line-height: 22px;
}

.bsc-home_li {
  background-image: linear-gradient(270deg, #ffc62c 0%, #ffa954 100%)
}

.bsc-home_li-span {
  color: #ffffff !important;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  line-height: 22px;
}

.eth-title {
  color: #ffffff !important;
}

.eth-home_li {
  background-image: linear-gradient(270deg, #1165E4 2%, #233BB8 100%);
}

.eth-home_li-span {
  color: #ffffff !important;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  line-height: 22px;
}

.bsc-title {
  color: #ffffff !important;
}

/* 领取空投样式 */
.home__dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.home__dialog-inner {
  box-sizing: border-box;
  width: 600px;
  background-color: #fff;
  border-radius: 16px;
  padding: 40px 60px 48px;
  padding-top: 28px;
  font-size: 14px;
}

.home__dialog__header {
  font-size: 20px;
  color: #131d32;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
  position: relative;
}

.home__dialog__header__close {
  position: absolute;
  right: -50px;
  top: -10px;
  width: 16px;
  height: 16px;
  background: url(../../assets/img/icon-close.svg) no-repeat center center;
  border: 10px solid transparent;
  cursor: pointer;
  z-index: 3;
}

.home__dialog__header__close:active {
  opacity: 0.7;
}

.drop__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #131D32;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 400;
}

.drop__amount {
  font-family: DINPro-Bold;
  font-size: 24px;
  color: #3078FF;
  letter-spacing: 0;
  text-align: right;
  line-height: 28px;
  font-weight: 700;
}

.drop__amount.drop__amount-bsc {
  color: #FFA34F;
}

.drop__center {
  width: 100%;
  background: #F6F8FB;
  border-radius: 16px;
  padding: 0 40px;
  box-sizing: border-box;
  padding-top: 32px;
  padding-bottom: 32px;
}

.drop__wrap {
  display: flex;
  justify-content: space-between;
}

.center__left {
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #131D32;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 400;
}

.center__left .left__amount {
  font-family: DINPro-Medium;
  font-size: 24px;
  color: #131D32;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 500;
  margin-top: 12px;
  position: relative;
  text-indent: 20px;
}

.center__left .left__amount::before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #00B595;
}

.center__right {
  width: auto;
  min-width: 140px;
}

.drop__desc:first-of-type {
  margin-top: 24px;
}

.drop__desc:last-of-type {
  margin-top: 16px;
}

.drop__desc {
  font-family: DINPro-Medium;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  line-height: 18px;
  /* font-weight: 500; */
}

.drop__desc span {
  font-family: DINPro-Medium;
  font-size: 14px;
  color: #131D32;
  letter-spacing: 0;
  line-height: 18px;
  /* font-weight: 500; */
}

.btn__container {
  margin-top: 20px;
}

.btn__container span,
.btn__container a {
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #3078FF;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.btn__container a {
  margin-left: 20px;
}

.dao {
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #FFFFFF;
  height: 100%;
  padding: 0 40px;
  background: rgba(255, 255, 255, 0.80);
  box-sizing: border-box;
  border-radius: 16px;
}

.dao-top {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #EDEFF2;
  display: flex;
  justify-content: space-between;
}
.dao-top-left{
  display: flex;
}
.dao-botton{
  padding: 20px 0 3px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1280px) {
  .project__apy {
    margin-right: 12px;
  }

  .drop__info-item {
    width: 50%;
  }
}

@media screen and (max-width: 1200px) {
  .home__dialog-inner {
    max-width: 90vw;
    min-width: 50vw;
    padding: 20px 30px;
  }

  .home__dialog__header__close {
    right: -20px;
  }

  .home__dialog__header {
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 900px) {
  .home__dialog-inner {
    padding: 16px 24px 20px;
    width: 90vw;
    min-width: 90vw;
  }

  .home__dialog__header {
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #131D32;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .drop__wrap {
    display: unset;
    margin-bottom: 14px;
  }

  .drop__title {
    font-size: 12px;
    margin-bottom: 6px;
  }

  .drop__amount {
    font-size: 18px;
    line-height: 20px;
  }

  .drop__center {
    padding: 0 20px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .center__left {
    font-size: 12px;
  }

  .center__left .left__amount {
    font-size: 18px;
    line-height: 22px;
    margin-top: 6px;
  }

  .center__right .g-button {
    width: 140px;
    margin-top: 10px;
    height: 36px;
  }

  .drop__desc:first-of-type {
    margin-top: 12px;
  }

  .drop__desc {
    font-size: 12px;
    line-height: 14px;
  }

  .drop__desc span {
    font-size: 12px;
    line-height: 14px;
  }

  .drop__desc:last-of-type {
    margin-top: 8px;
  }

  .drop__info-item-text {
    font-size: 14px;
  }
}