.section-banner {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #ffffff;
  border-radius: 16px;
  max-width: 1200px;
  margin: 20px auto 0;
  height: 240px;
  overflow: hidden;
}
.section-banner img{
  width: 100%;
  /* height: 100%; */
}
@media screen and (max-width: 1200px) {
  .section-banner{
    margin: 20px 15px;
    height: 140px;
  }
}
