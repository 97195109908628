.nav__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    /* top: 300px; */
    width: 240px;
    max-height: calc(100vh - 300px);
    /* overflow-y: auto; */
    padding-right: 13px;
    box-sizing: border-box;
    /* background-color: rgba(255,255,255,0.8); */
    /* padding-bottom: 102px; */
}

.nav__tutoril {
    padding: 0 32px;
    box-sizing: border-box;
}

.nav__tutoril .tutoril__item {
    margin-bottom: 24px;
    height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__tutoril .tutoril__item span {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #8391A8;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
}

.nav__tutoril .tutoril__item img {
    width: 10px;
    height: 10px;
    display: block;
}

.navlist__item-container .nav__index {
    background: url("../../images/icon-index.svg") no-repeat center center;
}

.navlist__item-actived .nav__index,
.navlist__item-container:hover .nav__index {
    background: url("../../images/icon-index-blue.svg") no-repeat center center;
}

.navlist__item-actived .nav__index-bsc,
.navlist__item-container:hover .nav__index-bsc {
    background: url("../../images/index-bsc.svg") no-repeat center center;
}

.navlist__item-actived .nav__index-eth,
.navlist__item-container:hover .nav__index-eth {
    background: url("../../images/index-eth.svg") no-repeat center center;
}

.navlist__item-container .nav__danbi {
    background: url("../../images/icon-high.svg") no-repeat center center;
}

.navlist__item-actived .nav__danbi,
.navlist__item-container:hover .nav__danbi {
    background: url("../../images/icon-high-blue.svg") no-repeat center center;
}

.navlist__item-actived .nav__danbi-bsc,
.navlist__item-container:hover .nav__danbi-bsc {
    background: url("../../images/high-bsc.svg") no-repeat center center;
}

.navlist__item-actived .nav__danbi-eth,
.navlist__item-container:hover .nav__danbi-eth {
    background: url("../../images/high-eth.svg") no-repeat center center;
}


.navlist__item-container .nav__lp {
    background: url("../../images/icon-lp.svg") no-repeat center center;
}

.navlist__item-actived .nav__lp,
.navlist__item-container:hover .nav__lp {
    background: url("../../images/icon-lp-blue.svg") no-repeat center center;
}

.navlist__item-actived .nav__lp-bsc,
.navlist__item-container:hover .nav__lp-bsc {
    background: url("../../images/lp-bsc.svg") no-repeat center center;
}


.navlist__item-container .nav__dao {
    background: url("../../images/dao.svg") no-repeat center center;
}

.navlist__item-actived .nav__dao,
.navlist__item-container:hover .nav__dao {
    background: url("../../images/dao-blue.svg") no-repeat center center;
}

.navlist__item-actived .nav__dao-bsc,
.navlist__item-container:hover .nav__dao-bsc {
    background: url("../../images/dao-bsc.svg") no-repeat center center;
}

.navlist__item-actived .nav__dao-eth,
.navlist__item-container:hover .nav__dao-eth {
    background: url("../../images/dao-eth.svg") no-repeat center center;
}

.wen_ti {
    width: 70%;
    display: flex;
    justify-content: space-around;
    font-size: 15px;
    margin-top: 20px;
    margin: 20px auto;
}

.icon_all {
    display: flex;
    justify-content: space-around;
    width: 70%;
    margin: 0 auto;
}

.navList_ul {
    overflow-y: scroll;
    height: 450px;
}

.nav_Heigth {
    overflow-y: scroll;
}
::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
    }
