.repurchase-record {
    border-radius: 8px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    /* display: flex; */
}

.repurchase-record-title {
    width: 100%;
    height: 92px;
    display: flex;
    justify-content: space-between;
    background: #F6F8FB;
    border-radius: 24px 24px 0 0;
}
.heco-back{
    background: rgba(48, 120, 255, 0.08);
  }
  .bsc-back{
    background: rgba(255,163,79,.06);
  }
.repurchase-record-title-start {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #131D32;
    letter-spacing: 0;
    margin-top: 21px;
    font-weight: 700;
}

.repurchase-record-title-end {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #3179FE;
    letter-spacing: 0;
    text-align: right;
    margin-top: 24px;
    font-weight: 500;
    cursor: pointer;
}

.repurchase-record-content {
    background: rgba(255, 255, 255);
    /* border: 1px solid #ffffff; */
    border-radius: 24px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: -32px;
    /* display: flex;
    justify-content: space-between; */
    /* padding-left: 40px;
    padding-right: 48px; */
}

.repurchase-record-content-txid {
    margin-top: 24px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #C5C9D5;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
}

.repurchase-record-content-height {
    margin-top: 24px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #C5C9D5;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
}

.repurchase-record-content-price {
    margin-top: 24px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #C5C9D5;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
}

.repurchase-record-content-num {
    margin-top: 24px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #C5C9D5;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
}

.repurchase-record-content-value {
    margin-top: 24px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #C5C9D5;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
}

.pools-mains {
    max-width: 1200px;
    margin: 20px auto 0;
    display: flex;
    flex-wrap: wrap;
}

.pools__emptys {
    background: rgba(255, 255, 255);
    border: 1px solid #FFFFFF;
    border-radius: 24px;
    min-height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
}

.repurchase-record-content-inner {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    /* height: 100px; */
    border-radius: 24px;
    max-width: 1200px;
    margin: -32px 40px 0;
    padding-bottom: 20px;
}

.single-data {
    margin-top: 10px;
    font-family: DINPro-Medium;
    font-size: 16px;
    color: #3078FF;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    width: 150px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
}

.single-data-2 {
    margin-top: 10px;
    font-family: DINPro-Medium;
    font-size: 16px;
    color: #131D32;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    text-align: center;
}
@media screen and (max-width: 900px) {
    .list-title{
        font-size: 12px;
    }
    .repurchase-record {
        width: 100%;
        margin-top: 50px;
    }

    .repurchase-record-title,
    .repurchase-record-content {
        width: calc(100% - 40px);
        margin: -32px 20px 0;
    }

    .repurchase-record-content-inner {
        display: flex;
        justify-content: space-around;
        margin: -32px 5px 0;
        padding-bottom: 20px;
    }
    .single-data {
        margin-top: 10px;
        font-family: DINPro-Medium;
        font-size: 16px;
        color: #3078FF;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 500;
        cursor: pointer;
        width: 50px;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
    }
    .single-data-2{
        margin-top: 10px;
        font-family: DINPro-Medium;
        font-size: 16px;
        color: #131D32;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 500;
        width: 50px;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
    }
    .repurchase-record-title-start {
        margin-left:10px;
    }
    .repurchase-record-title-end{
        margin-right:10px;
    }
}
@media screen and (min-width: 1200px) {
    .repurchase-record-title-start {
        margin-left: 40px;
    }
    .repurchase-record-title-end{
        margin-right: 48px;
    }
}