.pool__total-tvl {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #ffffff;
  border-radius: 8px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  color: #3078FF;
}

.pool__total-tvl-bsc {
  color: #FFA34F;
}

.pool__total-tvl-eth {
  color: #233BB8 !important;
}

.pool__total-tvl li {
  flex: 1;
  text-align: center;
  padding: 20px 0;
}

.pool__total-tvl-home li {
  padding: 20px 0 0;
}

.pool__total-tvl li div:first-child {
  font-size: 14px;
  color: #8391a8;
  margin-bottom: 10px;
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
}

.pool__total-tvl li div:last-child {
  font-size: 18px;
  /* color: #131d32; */
  font-weight: bold;
  font-family: DINPro-Bold;
}

.new-top {
  margin-top: 20px !important;
}

.new_total-heco li div:first-child {
  font-size: 14px;
  color: #8391a8 !important;
  margin-bottom: 10px;
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
}

.new_total-heco li div:last-child {
  color: #3078FF !important;
  font-weight: 700 !important;
  font-family: DINPro-Bold !important;
}

.new_total-bsc li div:last-child {
  color: #FFA34F !important;
  font-weight: 700 !important;
  font-family: DINPro-Bold !important;
}

.new_total-eth li div:last-child {
  color: #233BB8 !important;
  font-weight: 700 !important;
  font-family: DINPro-Bold !important;
}

.bsc-texts {
  color: #FFA34F !important;
}

.bsc-spans {
  color: #FFA34F !important;
}

.drop__info-items {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16%;
  flex-direction: column;
}

.drop__info-item-texts {
  font-family: DINPro-Bold;
  font-size: 18px;
  color: #3078FF;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  margin-top: 10px;
  position: relative;
  width: 100%;
}

.spans-font {
  font-size: 12px !important;
  margin-top: 2px;
}

.drop__info-item-texts span {
  font-size: 14px;
  color: #3078FF;
  margin-left: 12px;
  line-height: 22px;
  position: absolute;
  cursor: pointer;
}

.extract-content_tops {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  height: 150px;
  overflow-y: scroll;
}

.extract-content_lis {
  width: 50%;
  text-align: center;
  font-family: DINPro-Bold;
  font-size: 20px;
  color: #131D32;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 200;
  margin-bottom: 20px;
}

.extract-content_lis>div:nth-child(2) {
  font-family: DINPro-Medium;
  font-size: 14px;
  color: #8391A8;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .pool__total-tvl {
    flex-wrap: wrap;
    margin: 0 15px;
    margin-top: 20px;
  }

  .pool__total-tvl li {
    padding: 16px 0;
    flex: auto;
    width: 50%;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .pool__total-tvl li:nth-child(1) div:last-child,
  .pool__total-tvl li:nth-child(2) div:last-child {
    /* color: #3078ff; */
  }

  .pool__total-tvl li div:last-child {
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .drop__info-item-texts {
    font-size: 14px;
  }
}