.g-pop-tips {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(../../assets/img/icon-question.svg) no-repeat center
    center;
  position: relative;
  margin-left: 5px;
  flex: none;
  margin-top: 2px;
}
.g-pop-tips::after{
  position: absolute;
  transform: translate(-25%,-25%);
  content: '';
  width: 200%;
  height: 200%;
  background-color: transparent;
}
.g-pop-tips__inner_two-xuss {
  position: absolute;
  min-width: 200px;
  max-width: 250px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px 0 #EDEFF2;
  border-radius: 16px;
  padding: 10px;
  border-radius: 5px;
  bottom: -10px;
  left: -230px;
  /* transform: translateX(-50%); */
  text-align: left;
}
.g-pop-tips__inner_two-xuss::after{
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  bottom: 10px;
  left:220px;
  transform: translateX(-50%) rotate(45deg);
}
.tip_staking_two>div:nth-child(1){
  line-height: 20px;
}